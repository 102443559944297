export default [
  {
    header: 'Dashboard',
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon'
  },
  {
    header: 'Customer',
    title: 'Customer',
    route: 'customer',
    sub: ['customer-add', 'customer-detail'],
    icon: 'UsersIcon'
  },
  {
    header: 'Ticket',
    title: 'Ticket',
    route: 'ticket',
    sub: ['ticket-add', 'ticket-detail'],
    icon: 'BookIcon'
  },
  {
    header: 'Knowledge',
    title: 'Knowledge',
    route: 'knowledge',
    sub: ['knowledge-add', 'knowledge-detail'],
    icon: 'SunIcon'
  },
  {
    header: 'Ticket Detail Report',
    title: 'Ticket Detail Report',
    route: 'report-ticket-detail',
    icon: 'BookIcon'
  },
  {
    header: 'Ticket Summary By Agent',
    title: 'Ticket Summary By Agent',
    route: 'report-summary-by-agent',
    icon: 'BookIcon'
  },
  {
    header: 'Ticket Summary By Channel',
    title: 'Ticket Summary By Channel',
    route: 'report-summary-by-channel',
    icon: 'BookIcon'
  },
  {
    header: 'Ticket Summary By Case Type',
    title: 'Ticket Summary By Case Type',
    route: 'report-summary-by-case-type',
    icon: 'BookIcon'
  },
  {
    header: 'Ticket Detail',
    title: 'Ticket Detail Report',
    route: 'ticket-detail-report',
    icon: 'BookIcon'
  },
  {
    header: 'Ticket Detail (New)',
    title: 'Ticket Detail Report (New)',
    route: 'ticket-detail-report-new',
    icon: 'BookIcon'
  },
  {
    header: 'Categories',
    title: 'Categories Report',
    route: 'categories-report',
    icon: 'BookIcon'
  },
  {
    header: 'Complain Report',
    title: 'Complain Report',
    route: 'complain-report',
    icon: 'BookIcon'
  },
  {
    header: 'Download Report',
    title: 'Download Report',
    route: 'report-download',
    icon: 'DownloadIcon'
  },
  // {
  //   header: 'Report',
  //   title: 'Report',
  //   icon: 'BookIcon',
  //   children: [
  //     {
  //       header: 'Ticket Detail Report',
  //       title: 'Ticket Detail Report',
  //       route: 'report-ticket-detail',
  //       icon: 'BookIcon'
  //     },
  //     {
  //       header: 'Ticket Summary By Agent',
  //       title: 'Ticket Summary By Agent',
  //       route: 'report-summary-by-agent',
  //       icon: 'BookIcon'
  //     },
  //     {
  //       header: 'Ticket Summary By Channel',
  //       title: 'Ticket Summary By Channel',
  //       route: 'report-summary-by-channel',
  //       icon: 'BookIcon'
  //     },
  //     {
  //       header: 'Ticket Summary By Case Type',
  //       title: 'Ticket Summary By Case Type',
  //       route: 'report-summary-by-case-type',
  //       icon: 'BookIcon'
  //     }
  //   ]
  // },
  {
    header: 'Favorite Email',
    title: 'Favorite Email',
    route: 'favorite-email',
    icon: 'StarIcon'
  }
]
